.bg {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: 1280px auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: auto;
}

.wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 140px;
}

.header {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}

.imageWrapper {
  height: 95px;
  width: 95px;
  position: absolute;
  z-index: 0;
}

.flowerImage {
  width: 100px;
  height: 90px;
  bottom: 90px;
  right: 90px;
}

.tubeImage {
  height: 80px;
  width: 60px;
  bottom: 20px;
  right: 40px;
}

.circleLogoImage {
  bottom: 20px;
  left: 40px;
  transform: rotate(-30deg);
}

@media only screen and (hover: none) and (pointer: coarse){
  .header  {
    padding-top: 60px;
  }
}
