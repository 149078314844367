.wrapper {
  display: inline-flex;
  gap: 8px;
}

.input {
  height: calc(var(--input-height) * 2);
  font-family: 'Saira ExtraCondensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 80px;
  width: 48px;
  outline: none;
  border: 2px solid var(--gray);
  color: var(--primary);
  caret-color: var(--text-default);
  text-align: center;
  border-radius: 6px;
}

.input[data-invalid='true'] {
  background: var(--gray);
}

.input[data-error="true"] {
  border-color: var(--text-danger);
}
