.smoothDropdownBehavior-enter {
  transition: all ease 0.3s;
}

.smoothDropdownBehavior-enter-active {
  transition: all ease 0.3s;
}

.smoothDropdownBehavior-exit-active {
  transition: max-height ease 0.3s;
  max-height: 0 !important;
}
