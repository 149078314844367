.wrapper {
  width: 100%;
  padding: 20px;
  padding-top: 0;

  min-height: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper[data-withContentPadding="false"] {
  padding: 20px 0;
  padding-top: 0;
}

.header {
  padding-bottom: 20px;
  margin-bottom: 10px;
  margin-left: -20px;
  width: calc(100% + 40px);
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.header[data-withContentPadding="false"] {
  padding-left: 20px;
  padding-right: 20px;
}

.header svg {
  cursor: pointer;
}

.userIcon {
  margin-right: 10px;
  color: var(--text-default);
}

.userIcon[data-active='true'] > path {
  color: var(--primary);
}

.notifications {
  position: relative;
  margin-right: 14px;
}

.footer {
  position: fixed;
  left: 50%;
  z-index: 12;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 50px;
  max-width: 420px;
  box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
}

.footerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  color: var(--text-default);
  cursor: pointer;
}

.footerItem:hover {
  color: var(--primary);
}


.footerItem[data-open="true"] {
  color: var(--primary);
}


.footerItemTitle {
  font-family: Inter, 'Montserrat';
  font-size: 10px;
  color: var(--text-gray);
}


.footerItem[data-open="true"] .footerItemTitle {
  color: var(--primary);
}
