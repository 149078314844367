.checkbox {
    position: relative;
    height: 28px;
    width: 28px;
    flex-shrink: 0;
}

.checkbox label {
    border: 1px solid var(--text-default);
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
}

.checkbox label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.checkbox input[type="checkbox"] {
    visibility: hidden;
}

.checkbox input[type="checkbox"]:checked + label {
    background-color: var(--primary);
    border-color: var(--primary);
}

.checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
}