.app-modal__shadow {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  overflow: auto;
  z-index: 999;
  background: rgba(255, 255, 255, 0.81);
  height: 100%;

}

.app-modal {
  width: 100vw;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  position: relative;
  flex: 1;
}

.app-modal.is-sm {
  width: fit-content;
  min-width: 200px;
  max-width: 322px;
  height: fit-content ;
  padding: 24px 24px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(44, 92, 250, 0.4);
  transform: translateY(-50%);
}

.app-modal__content {
  padding: 34px 20px;
}

.app-modal__option {
  padding: 20px;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.15);
}

.app-modal__option[data-selected="true"] {
  background: var(--primary);
  color: #fff;
}

.app-modal-animation.app-modal__shadow {
  animation-name: modalShadowAppear;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.app-modal-animation > .app-modal {
  transform-origin: center;
  animation-name: appModalAppear;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.confirm-modal {
  text-align: center;
}

.app-modal__footer {
  text-align: right;
  margin-top: 1rem;
}

.app-modal__close-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 14px;
  right: 14px;
}


@keyframes appModalAppear {
  from {
    opacity: 0.1;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalShadowAppear {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(255, 255, 255, 0.81);
  }
}
