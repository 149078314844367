.phoneInput {
    padding-left: calc(15px + 1ch);
}

.wrapper {
    width: 100%;
    position: relative;
}

.plus {
    position: absolute;
    left: 15px;
    top: calc(var(--input-height) / 2);
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    align-items: center;
}