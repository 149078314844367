.wrapper {
  position: relative;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 50px 50px !important;
  z-index: 10;
  background-color: #fff !important;
}

.uploader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 50px 50px !important;
  z-index: 10;
  background-color: #ffffff99 !important;
}

.image {
  width: 100%;
  height: 100%;
}