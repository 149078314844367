.wrapper {
  display: inline-flex;
  width: 100%;
}

.tab {
  height: var(--input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  border: 1px solid transparent;
}

.tab:hover {
  color: var(--text-gray);
}

.tab[data-active='true'] {
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: var(--input-border-radius);
}
