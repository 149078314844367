.paragraph {
    margin: 0;
    padding-top: 35px;
}

.smilingFace {
    transform: scale(0.5);
}

.wrapper {
    height: auto;
    flex-grow: 1;
    padding: 65px 10% 0;
    padding-bottom: 40px;
}

.subheader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 65px;
    position: relative;
    width: 100%;
    max-width: 350px;
}

.leftStar {
    position: absolute;
    left: 0;
    bottom: 33px;
    width: 33px;
    height: 33px;
}

.rightStart {
    position: absolute;
    right: 0;
    top: -20%;
    width: 33px;
    height: 33px;
}
